import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  makeStyles,
  createStyles,
  Menu,
  MenuItem,
  ListItemIcon,
  Button,
  Link,
  Divider, CircularProgress,
} from '@material-ui/core'
import ChatIcon from '@material-ui/icons/Chat'
import { RootState } from 'state-mngt/store'
import { getCompanyDetails } from 'state-mngt/actions/company-actions'
import { useMixPanel } from 'features/analytics/mixpanel-provider'
import { useDispatch, useSelector } from 'react-redux'
import config from 'config'
import { HavenLinks } from 'utils/constants/resources/links'
import { ROUTE_PATHS } from 'routes/routes'
import logoImageUrl from 'ui/navigation-bar/logo.svg'
import { selectUserRole, selectIsProPortalTourEnabled } from 'state-mngt/selectors/user-selectors'
import { selectIsCompanyDetailsLoading, selectCompanyDetails } from 'state-mngt/selectors/company-selectors'
import { useAuth0 } from '@auth0/auth0-react'
import { ExpandMore, PlayArrow } from '@material-ui/icons'
import { PRODUCT_TOUR_QUERY_KEYS, ProductTourPageName } from 'features/product-tour/pro-portal-tour'
import { enableDemoMode } from 'state-mngt/actions/user-actions'
import { AccessControl } from 'features/protect-route'
import { MemberRole } from 'utils/constants/member-enums'
import 'ui/navigation-bar/navigation-bar.scss'
import $ from 'jquery'

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbar: {
      backgroundColor: theme.palette.grey[400],
      boxShadow: '2px 0px .25rem rgba(0, 0, 0, .3)',
      justifyContent: 'space-between',
      color: '#FFFFFF', //'#000000' //
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    divider: {
      marginTop: 8,
      marginBottom: 8,
    },
    menuButton: {
      ...theme.typography.button,
      color: 'white',
      marginRight: '1em',
      textAlign: 'center',
      padding: '1.5em 1em',
      position: 'relative',
      border: '0px solid ',
      zindex: 2,
    },
    active: { //active menu button top nav
      ...theme.typography.button,
      color: 'white',
      marginRight: '1em',
      textAlign: 'center',
      padding: '1.5em 1em',
      position: 'relative',
      borderBottom: theme.palette.primary.main + ' 4px solid',
      borderRadius: 4,
      zindex: 2,
    },
    whiteButton: {
      marginLeft: '.5rem',
      color: theme.palette.text.primary,
      backgroundColor: '#FFFFFF',
      border: '1px solid ' + theme.palette.grey[500],
      borderRadius: '4px',
      marginTop: 'auto',
      marginBottom: 'auto',
      marginRight: 0,
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
    },

  }),
)

/**
 * Renders header (navbar)
 */
function NavigationBar() {
  const classes = useStyles()
  const { mixpanel } = useMixPanel()
  const {
    isLoading, isAuthenticated, logout, user,
  } = useAuth0()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const companyDetails = useSelector(selectCompanyDetails)
  const isLoadingCompany = useSelector(selectIsCompanyDetailsLoading)
  const isLoadingUserRole = useSelector((state: RootState): boolean => state.user.isLoadingRole)
  const userRole = useSelector(selectUserRole)
  const isProPortalTourEnabled = useSelector(selectIsProPortalTourEnabled)

  const [anchorResourcesElement, setAnchorResourcesElement] = useState<null | HTMLElement>(null)
  const [anchorGetStartedElement, setanchorGetStartedElement] = useState<null | HTMLElement>(null)
  const [anchorSettingsElement, setanchorSettingsElement] = useState<null | HTMLElement>(null)

  useEffect(() => {
    if (isAuthenticated && userRole && userRole.service_company_id !== -1) {
      dispatch(getCompanyDetails(userRole.service_company_id))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userRole])

  useEffect(() => {
    if (mixpanel) {
      mixpanel.init(config.mixpanelId, {}, '')
      mixpanel.track('pp_login') // event tracking
    }
  }, [mixpanel])

  const handleHowToBuyMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (mixpanel) {
      mixpanel.track('pp_navBar_resourcesMenuButton')
    }

    setAnchorResourcesElement(event.currentTarget)
  }

  const handleHowToBuyMenuClose = () => {
    setAnchorResourcesElement(null)
    showMenu()
  }

  const handleGetStartedMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (mixpanel) {
      mixpanel.track('pp_navBar_quickGuideMenuButton')
    }

    setanchorGetStartedElement(event.currentTarget)
  }

  const handleGetStartedMenuClose = () => {
    setanchorGetStartedElement(null)
  }


  const handlePreferencesMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (mixpanel) {
      mixpanel.track('pp_navBar_preferencesMenuButton')
    }

    setanchorSettingsElement(event.currentTarget)
  }

  const handleSettingsMenuClose = () => {
    setanchorSettingsElement(null)
    showMenu()
  }

  const handleLogout = () => {
    mixpanel?.reset()
    localStorage.removeItem('token')
    logout({
      returnTo: window.location.origin,
    })
  }


  const showMenu = () => { //$('.navbar-icon').onclick(function(){
    $('.navbar-icon').toggleClass('active')
    $('.navbar-menu nav').toggleClass('active')
  }

  // const hideMenu = () => { //$('.navbar-icon').onclick(function(){
  //   //alert('test');

  //   // $('.navbar-icon').toggleClass('active');
  //   // $('.navbar-menu nav').toggleClass('hidden');
  //    //showMenu();

  // };


  if (isLoading) {
    return (//was "flex"  inline
      <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
        <CircularProgress />
      </Box>
    )
  }

  if (!isLoading && !isAuthenticated) {
    return null
  }


  return (
    <>
      <AppBar position="static">
        <Toolbar className={classes.toolbar} id="nav-header">
          <Box display="flex" alignItems="center">
            <NavLink to={ROUTE_PATHS.root}>
              <img
                src={logoImageUrl}
                alt="logo"
                style={{
                  marginTop: '.5rem',
                }}
                width={128}
                height={40}
              />
            </NavLink>
            <Divider orientation="vertical" variant="middle" className={classes.divider} flexItem light />
            {!isLoadingCompany && !isLoadingUserRole && companyDetails && <Typography variant="h5">{companyDetails.name}</Typography>}
            {isLoadingUserRole && <p>Loading company info ...</p>}
          </Box>
          {isAuthenticated && user && (
            <div className='navbar'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-4 col-8 navbar-logo'></div>
                  <div className='col-md-8 col-4 navbar-menu'>
                    <nav>
                      {/* onClick={hideMenu} */}
                      <ul>
                        <li><a href="#" onClick={showMenu}>
                          <NavLink to={ROUTE_PATHS.customers.root.absolute}
                            className={({ isActive }) => isActive ? classes.active : classes.menuButton}
                          >
                            Customers
                          </NavLink></a></li>
                        <li><a href="#" onClick={showMenu}>
                          {/* RESOURCES MENU BUTTON */}
                          <NavLink to={ROUTE_PATHS.resources.salesAndMarketing.root.absolute}
                            className={({ isActive }) => isActive ? classes.active : classes.menuButton}
                          >
                            Resources
                          </NavLink></a></li>

                        <li><a href="#">
                          {/* HOW TO BUY MENU BUTTON */}
                          <Button
                            aria-controls="how-to-buy-menu"
                            aria-haspopup="true"
                            variant="outlined"
                            endIcon={<ExpandMore />}
                            onClick={handleHowToBuyMenuOpen}
                            className={classes.menuButton}
                          >
                            How To Buy
                          </Button>
                          <Menu
                            id="how-to-buy-menu"
                            getContentAnchorEl={null}
                            anchorEl={anchorResourcesElement}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                            keepMounted={Boolean(anchorResourcesElement)}
                            open={Boolean(anchorResourcesElement)}
                            onClose={handleHowToBuyMenuClose}
                          >
                            <MenuItem
                              component={Link}
                              href={'https://haveniaq.com/distributors/'}
                              onClick={handleHowToBuyMenuClose}
                              target="_blank"
                              rel="noopener noreferrer"
                              color="textPrimary"
                            >
                              <Typography variant="inherit">Find wholesaler</Typography>
                            </MenuItem>
                            <MenuItem
                              component={NavLink}
                              to={ROUTE_PATHS.howToBuy.personalUseProgram.absolute}
                              onClick={handleHowToBuyMenuClose}
                              color="textPrimary"
                            >
                              <Typography variant="inherit">Personal Use Program</Typography>
                            </MenuItem>
                             <MenuItem
                              component={Link}
                              href={'https://haveniaq.myshopify.com/'}
                              onClick={handleHowToBuyMenuClose}
                              target="_blank"
                              rel="noopener noreferrer"
                              color="textPrimary"
                            >
                              <Typography variant="inherit">Purchase Now</Typography>
                            </MenuItem>
                          </Menu></a></li>
                        <li><a href="#">
                          {/*  GET STARTED BUTTON */}
                          {isProPortalTourEnabled &&
                            <>
                              <Button
                                aria-controls="get-started-menu"
                                aria-haspopup="true"
                                variant="outlined"
                                endIcon={<ExpandMore />}
                                onClick={handleGetStartedMenuOpen}
                                className={classes.menuButton}
                              >
                                Get Started
                              </Button>
                              <Menu
                                id="get-started-menu"
                                getContentAnchorEl={null}
                                anchorEl={anchorGetStartedElement}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                keepMounted={Boolean(anchorGetStartedElement)}
                                open={Boolean(anchorGetStartedElement)}
                                onClose={handleGetStartedMenuClose}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleGetStartedMenuClose()
                                    showMenu()
                                    navigate({
                                      pathname: ROUTE_PATHS.customers.root.absolute,
                                      search: `${PRODUCT_TOUR_QUERY_KEYS.currentPageTour}=${ProductTourPageName.Customers}`,
                                    })
                                  }}
                                  color="textPrimary"
                                >
                                  <ListItemIcon><PlayArrow /></ListItemIcon>
                                  <Typography variant="inherit">Pro Portal Tour</Typography>
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleGetStartedMenuClose()
                                    showMenu()
                                    dispatch(enableDemoMode())
                                    navigate(ROUTE_PATHS.customers.root.absolute, {
                                      replace: true,
                                    })
                                  }}
                                  color="textPrimary"
                                >
                                  <ListItemIcon><PlayArrow /></ListItemIcon>
                                  <Typography variant="inherit">Demo Accounts Tour</Typography>
                                </MenuItem>
                              </Menu>
                            </>
                          }</a></li>
                        <li><a href="#" onClick={showMenu}>
                          {/* MANAGE TEAM BUTTON */}
                          <AccessControl roles={[MemberRole.ADMIN]}>
                            <NavLink
                              to={ROUTE_PATHS.teamMngt.root.absolute}
                              className={({ isActive }) => isActive ? classes.active : classes.menuButton}
                            >
                              Manage Team
                            </NavLink>
                          </AccessControl></a></li>
                        <li><a href="#">
                          {/* MY ACCOUNT MENU BUTTON */}
                          <Button
                            aria-controls="my-account-menu"
                            aria-haspopup="true"
                            variant="outlined"
                            endIcon={<ExpandMore />}
                            onClick={handlePreferencesMenuOpen}
                            className={classes.menuButton}
                          >
                            My Account
                          </Button>
                          <Menu
                            id="my-account-menu"
                            getContentAnchorEl={null}
                            anchorEl={anchorSettingsElement}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                            keepMounted={Boolean(anchorSettingsElement)}
                            open={Boolean(anchorSettingsElement)}
                            onClose={handleSettingsMenuClose}
                          >
                            <MenuItem
                              component={NavLink}
                              to={ROUTE_PATHS.preferences.settings.absolute}
                              onClick={handleSettingsMenuClose}
                              color="textPrimary"
                            >
                              <Typography variant="inherit">Settings</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>Log out</MenuItem>
                          </Menu></a></li>
                        <Button
                          variant="outlined"
                          href={HavenLinks.SUPPORT}
                          className={classes.whiteButton}
                          endIcon={<ChatIcon />}
                          target="_blank"
                        >
                          Support
                        </Button>
                      </ul>
                    </nav>
                    <div className="navbar-icon" onClick={showMenu}>
                      <span>
                      </span>
                    </div>
                    {/* </Box>
                  </Box>
                )} */}
                  </div>
                </div>
              </div>
            </div>

          )}
        </Toolbar>
      </AppBar>
    </>
  )
}

export default NavigationBar
